<template>
    <div>
        <ts-page-title
            :title="$t('pensionRate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('pensionRate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template
                    slot-scope="{ row }"
                    slot="employee_contribution_rate"
                >
                    <strong>{{
                        formatNumber(row.employee_contribution_rate)
                    }}</strong>
                </template>
                <template
                    slot-scope="{ row }"
                    slot="employer_contribution_rate"
                >
                    <strong>{{
                        formatNumber(row.employer_contribution_rate)
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('pensionRate.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from './form.vue'
import { mapState,mapGetters } from 'vuex'

export default {
    name: 'pension-rate',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            showForm: false
        }
    },
    computed: {
        ...mapGetters(['formatNumber']),
        ...mapState('payroll/pensionRate', ['resources', 'pagination']),
        columns () {
            return [
                {
                    title: this.$t('pensionRate.dateForm'),
                    key: 'date_from',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('pensionRate.dateTo'),
                    key: 'date_to',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('pensionRate.employeeContributionRate'),
                    slot: 'employee_contribution_rate',
                    sortable: true,
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('pensionRate.employerContributionRate'),
                    slot: 'employer_contribution_rate',
                    sortable: true,
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('pensionRate.remark'),
                    key: 'remark',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        addNew () {
            (this.showForm = true), this.$refs.form_action.clearInput()
        },
        clearEdit () {
            (this.showForm = false),
                this.$store.commit('payroll/pensionRate/SET_EDIT_DATA', {})
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/pensionRate/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (record) {
            (this.showForm = true),
                this.$store.commit('payroll/pensionRate/SET_EDIT_DATA', record)
            this.$refs.form_action.setEditData()
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "payroll/pensionRate/destroy",
                    record.id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PENSION RATE',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
